import React from 'react';

function Footer(props){
  return(
   <center>
    <footer>
      <p>Copyright 	&copy; {props.year} Nwachukwu Uzochukwu</p>
    </footer>
   </center>
  )
}

export default Footer;